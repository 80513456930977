.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ------------------------------------------------------------------------------------------- */
.imageAnimation {
  transition: all 1s ease-in-out;
  object-fit:  cover;
  position: relative;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 15px;
}

.imageAnimation:hover {
  transform: scale(1.3);
  transform-origin: 50% 50%;
  border-radius: 15px;

}

/* ------------------------------------------------------------------------------- */


@keyframes color {
  0%   { background: #33CCCC; }
  20%  { background:skyblue; }
  60%  { background: teal; }
  40%  { background: royalblue; }
  80%  { background: lightblue; }
  100% { background: #33CCCC; }
}

.myaccount {
  background: #33CCCC; /* Fallback */
  animation: color 9s infinite linear;
  text-align: center;
  padding: 2em;
}
.heading {
  text-align: center;
  font-family: 'Kavoon', sans-serif;
  font-size: 2.5em;
  color: white;
}

.swal-text {
  background-color: black;
  padding: 17px;
  border: 1px solid black;
  display: block;
  margin: 22px;
  text-align: center;
  color: white;
}
.swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: black;
  font-size: 12px;
  border: 1px solid white;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}
.swal-overlay {
  background-color: rgba(0, 0, 0, 0.45);
}

.marquee {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fefefe;
  color: #333;
  border: 1px solid #4a4a4a;
}

.marquee p {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 50px;
  text-align: center;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -moz-animation: scroll-left 2s linear infinite;
  -webkit-animation: scroll-left 2s linear infinite;
  animation: scroll-left 20s linear infinite;
}
.Navbar{
  position: fixed !important;
  left: 0;
  right: 0;
  z-index: 9999;
}
@-moz-keyframes scroll-left {
  0% {
      -moz-transform: translateX(100%);
  }
  100% {
      -moz-transform: translateX(-100%);
  }
}

@-webkit-keyframes scroll-left {
  0% {
      -webkit-transform: translateX(100%);
  }
  100% {
      -webkit-transform: translateX(-100%);
  }
}




@keyframes scroll-left {
  0% {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
  }
  100% {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
  }
}



.success {
  text-align: center;

  background: #EBF0F5;
  align-content: center;


}



.card {

    justify-content: center;
    background: white;

    border-radius: 4px;

    display: inline-block;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* margin: 40px 20px; */


}
.subCard{
  border-radius:200px; height:200px; width:200px; background: #F8FAF5; margin:0 auto;
}
.subCard i {
  color: #9ABC66;
  font-size: 100px;
  line-height: 200px;
  margin-left:-15px;
}
.subCard:has(.cross){
  border-radius:200px; height:200px; width:200px; background: #f6e0e0; margin:0 auto;
}
.subCard .cross {
  color: #c54829;
  font-size: 100px;
  line-height: 200px;
  margin-left:-15px;
}
.failtext {
  color: #c54829 !important;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
.card h1 {
  color: #88B04B;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
.card p {
  color: #404F5E;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size:20px;
  margin: 0;
}

@media (width<= 300px ) {
  btngrp{
    display: flex;
    flex-direction: column;
  }
}