/* * {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  body, html {
    height: 100%;
  }

  body {
      background: #dfebed;
      font-family: 'Roboto', sans-serif;
  }
   */
  .container {
  	align-items: center;
  	/* display: flex; */
  	height: 100%;
  	justify-content: center;
  	margin:0 auto;
  	max-width: 600px;
  	width: 100%;
  }

  .calendar {
      background: #2b4450;
      border-radius: 4px;
      box-shadow: 0 5px 20px rgba(0, 0, 0, .3);
      height: 330px;
    perspective: 1000;
  	transition: .9s;
  	transform-style: preserve-3d;
      width: 100%;

  }

  /* Front - Calendar */
  .front {
      transform: rotateY(0deg);
  }

  .current-date {
      border-bottom: 1px solid rgba(73, 114, 133, .6);
      display: flex;
      justify-content: space-between;
      padding: 30px 40px;
  }

  .current-date h1 {
      color: #dfebed;
      font-size: 1.4em;
      font-weight: 300;
  }

  .week-days {
      color: #dfebed;
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      padding: 30px 40px;
  }

  .days {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
  }

  .weeks {
      color: #fff;
      display: flex;
      flex-direction: column;
      padding: 0 40px;
  }

  .weeks div {
      display: flex;
      font-size: 1.2em;
      font-weight: 300;
      /* justify-content: flex-end; */
      margin-bottom: 20px;
      width: 100%;
  }

  .last-month {
      opacity: .3;
  }

  .weeks span {
      padding: 10px;
  }

  .weeks span.active {
      background: #13acbc;
      border-radius: 50%;

  }

  .weeks span:not(.last-month):hover {
      cursor: pointer;
      font-weight: 600;
  }

  .event {
      position: relative;
  }

  .event:after {
      content: '•';
      color: #f78536;
      font-size: 1.4em;
      position: absolute;
      right: -4px;
      top: -4px;
  }

  /* Back - Event form */

  .back {
      height: 100%;
      transform: rotateY(180deg);
  }

  .back input {
      background: none;
      border: none;
      border-bottom: 1px solid rgba(73, 114, 133, .6);
      color: #dfebed;
      font-size: 1.4em;
      font-weight: 300;
      padding: 30px 40px;
      width: 100%;
  }

  .info {
      color: #dfebed;
      display: flex;
      flex-direction: column;
      font-weight: 600;
      font-size: 1.2em;
      padding: 30px 40px;
  }

  .info div:not(.observations) {
      margin-bottom: 40px;
  }

  .info span {
      font-weight: 300;
  }

  .info .date {
      display: flex;
      justify-content: space-between;
  }

  .info .date p {
      width: 50%;
  }

  .info .address p {
      width: 100%;
  }

  .actions {
      bottom: 0;
      border-top: 1px solid rgba(73, 114, 133, .6);
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: 100%;
  }

  .actions button {
      background: none;
      border: 0;
      color: #fff;
      font-weight: 600;
      letter-spacing: 3px;
      margin: 0;
      padding: 30px 0;
      text-transform: uppercase;
      width: 50%;
  }

  .actions button:first-of-type {
      border-right: 1px solid rgba(73, 114, 133, .6);
  }

  .actions button:hover {
      background: #497285;
      cursor: pointer;
  }

  .actions button:active {
      background: #5889a0;
      outline: none;
  }

  /* Flip animation */

  .flip {
    transform: rotateY(180deg);
  }

  .front, .back {
      backface-visibility: hidden;
  }

